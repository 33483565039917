<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div class="row" v-if="failures">
            <div class="col-md-12 failure-div">
                <div v-for="error in failures" :key="error">
                    <div v-for="e in error.errors" :key="e">
                        <b-alert show variant="danger" fade>{{ e }} at row {{ error.row }}</b-alert>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="error_responses.length > 0">
            <div class="col-md-12">
                <table class="import-tabe table table-responsive">
                    <thead>
                    <th>Row Number</th>
                    <th v-for="(error,index) in error_responses[0].row" :key="index">{{index}}</th>
                    <th>Errors</th>
                    </thead>
                    <tbody>
                    <tr v-for="(error,index) in error_responses" :key="index">
                        <td>{{error.row_number}}</td>
                        <td v-for="(err,index) in error.row" :key="index">{{err}}</td>
                        <td>
                            <ol>
                                <li v-for="(err,index) in error.errors" :key="index">
                                    <span v-if="index !== 'questions'"> {{err}}</span>
                                    <span v-if="index === 'questions'">
                                        {{'Questions'}}
                                        <ol>
                                            <li v-for="(err,index) in error.errors.questions" :key="index">{{index}}
                                                <ul>
                                                    <li v-for="(err,index) in err" :key="index">
                                                    {{err}}
                                                    </li>
                                                </ul>

                                            </li>
                                        </ol>
                                    </span>
                                </li>
                            </ol>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Import Images</h4>
                            </div>
                            <div class="card-body">
                                <div class="row d-flex justify-content-center" style="margin: 0px;">
                                    <div class="col-md-12">
                                        <form-wizard class="import-spot-wizard" ref="formwizard" @onComplete="onComplete" @onNextStep="nextStep" @onPreviousStep="previousStep" @onReset="reset">
                                            <tab-content title="Upload File">
                                                <div>
                                                    <h5>Upload downloaded file from reports section with media names</h5>
                                                </div>
                                                <Circle4 v-if="isUploading"></Circle4>
                                                <VueFileAgent v-if="!isUploading" ref="vueFileAgent" :theme="'list'"
                                                        :multiple="false" :deletable="true" :meta="true"
                                                        :accept="'.csv,.xls,.xlsx'" :maxSize="'10MB'"
                                                        :maxFiles="1"
                                                        :helpText="'Choose excel file format (.xlx, .xlsx)'"
                                                        :errorText="{
                                                            type: 'Invalid file type. Only excel file Allowed',
                                                            size: 'Files should not exceed 10MB in size',
													}" @select="filesSelected($event)"
                                                              @beforedelete="onBeforeDelete($event)"
                                                              @delete="fileDeleted($event)"
                                                              v-model="fileRecords"></VueFileAgent>
                                                <button v-if="!isUploading" type="button"
                                                        :disabled="!fileRecordsForUpload.length" @click="importImagesFile()">
                                                    Upload File
                                                </button>
                                            </tab-content>
                                            <tab-content title="Upload Images">
                                                <Circle4 v-if="isMediaUpload"></Circle4>
                                                <VueFileAgent v-if="!isMediaUpload" ref="mediaFiles" :theme="'list'"
                                                              :multiple="true" :deletable="true" :meta="true"
                                                              :accept="'.jpg,.jpeg,.png,.mp3,.pcm,.wav,.aiff,.aac,.ogg,.wma,.flac,.alac,.jfif,.tif'"
                                                              :maxSize="'100MB'" :maxFiles="10000000000"
                                                              :helpText="'Click or drag your image/audio files here'"
                                                              :errorText="{
													type: 'Invalid file type. Allowed: .jpg,.jpeg,.png,.jfif,.mp3,.pcm,.wav,.aiff,.aac,.ogg,.wma,.flac,.alac',
													size: 'Files should not exceed 100MB in size',
													}" @select="mediaSelected($event)"
                                                              @beforedelete="onBeforeMediaDelete($event)"
                                                              @delete="mediaDeleted($event)"
                                                              v-model="mediaRecords"></VueFileAgent>
                                                <button v-if="!isMediaUpload" type="button"
                                                        :disabled="!mediaRecordsForUpload.length"
                                                        @click="uploadImportMedia()">Upload File
                                                </button>
                                            </tab-content>
                                            <tab-content title="Data View">
                                                <!-- <Circle4 v-if="isMediaUpload"></Circle4> -->
                                                <div class="row">
                                                    <div class="col-lg-12 stretch-card">
                                                        <div class="card">
                                                            <div class="card-header">
                                                                <h4 class="card-title">{{ $t('spots') }}</h4>
                                                            </div>
                                                            <div class="card-body">
                                                                <vue-good-table
                                                                    mode="remote"
                                                                    :rows="spotsData"
                                                                    :columns="columns"
                                                                    :fixed-header="true"
                                                                    :line-numbers="true"
                                                                >
                                                                    <!-- <template slot="table-row" slot-scope="props">
                                                                        <span v-if="props.column.field === 'action'" class="text-nowrap">
                                                                            <toggle-button
                                                                                v-model="spots.find(p => p.id === props.row.id).spot_status"
                                                                                @change="changeStatus(props.row.id, $event)"
                                                                                :sync="true"
                                                                                class="mr-1 switchColor"
                                                                            />
                                                                            <b-button
                                                                                size="sm"
                                                                                @click="editSpot(props.row.id,props.row.spot_type)"
                                                                                class="mr-1 btn-info text-white"
                                                                            >{{ $t('edit-btn') }}</b-button>
                                                                            <b-button
                                                                                size="sm"
                                                                                @click="deleteClientSpot(props.row.id)"
                                                                                class="mr-1 btn-danger text-white"
                                                                            >{{ $t('delete-btn') }}</b-button>
                                                                        </span>
                                                                    </template> -->
                                                                </vue-good-table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tab-content>
                                        </form-wizard>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- </form> -->
    </section>
</template>

<script>
    import Vue from "vue";
    import SortedTablePlugin from "vue-sorted-table";
    import API from "@/api";
    import Snotify, {
        SnotifyPosition
    } from "vue-snotify";
    import {
        validationMixin
    } from "vuelidate";
    import moment from "moment";
    import {
        Circle4
    } from 'vue-loading-spinner';

    import {
        required,
        minLength,
        email,
        requiredIf,
        sameAs,
        between
    } from "vuelidate/lib/validators";
    import Tinybox from "vue-tinybox";
    import EnlargeableImage from "@diracleo/vue-enlargeable-image";
    import {
        FormWizard,
        TabContent,
        ValidationHelper
    } from "vue-step-wizard";
    import "vue-step-wizard/dist/vue-step-wizard.css";

    const options = {
        toast: {
            position: SnotifyPosition.rightTop
        }
    };
    Vue.use(Snotify, options);
    Vue.component("Tinybox", Tinybox);
    Vue.component("EnlargeableImage", EnlargeableImage);
    // Vue.use(VueStepWizard);
    export default {
        name: "upload_spot_images",
        mixins: [validationMixin, ValidationHelper],
        components: {
            FormWizard,
            TabContent,
            Circle4
        },
        data() {
            return {
                fileRecords: [],
                mediaRecords: [],
                mediaFilesData: [],
                // uploadUrl: "http://watch-spotter.test/api/importSpots",
                // uploadUrl: 'https://test.watchspotter.app/api/importSpots',
                uploadUrl: process.env.VUE_APP_ENDPOINT + '/api/importSpots',
                // uploadUrl: 'https://web.watchspotter.app/api/importSpots',
                uploadHeaders: {
                    "X-Test-Header": "vue-file-agent"
                },
                fileRecordsForUpload: [],
                mediaRecordsForUpload: [],
                form: {
                    client_id: JSON.parse(localStorage.getItem("client_id"))
                },
                client: JSON.parse(localStorage.getItem("client_settings")),
                isTesting: false,
                isUploading: false,
                isMediaUpload: false,
                failures: [],
                error_responses: [],
                spotsData: [],
                uploadedFilesData: [],
                mediaStores: [],
                columns: [
                    {
                        label: "Spot ID",
                        field: "spot_id",
                        // filterable: true
                    },
                    {
                        label: "Media 1",
                        field: "image1",
                        tdClass: this.tdClassImageOne,
                        // filterable: true
                    },
                    {
                        label: "Media 2",
                        field: "image2",
                        tdClass: this.tdClassImageTwo,
                        // filterable: true
                    },
                    {
                        label: "Media 3",
                        field: "image3",
                        tdClass: this.tdClassImageThree,
                        // filterable: true
                    }
                ],
                // validationRules: [
                //   { fileRecords: { required } },
                //   { fileRecords: { required } },
                // ]
            };
        },
        beforeCreate() {
        },
        beforeMount() {
        },
        async mounted() {
        },
        created() {
            this.getAppSettings();
        },
        methods: {
            tdClassImageOne(row) {
                console.log('row', row);
                if (!this.uploadedFilesData.includes(row.image1)) {
                    return 'red-validation';
                } else return 'green-validation';
            },
            tdClassImageTwo(row) {
                console.log('row', row);
                if (!this.uploadedFilesData.includes(row.image2)) {
                    return 'red-validation';
                } else return 'green-validation';
            },
            tdClassImageThree(row) {
                console.log('row', row);
                if (!this.uploadedFilesData.includes(row.image3)) {
                    return 'red-validation';
                } else return 'green-validation';
            },
            testFiles: function () {
                this.isTesting = true;
                this.failures = [];
                const data = {
                    file: this.fileRecordsForUpload[0].file,
                    ext: this.fileRecordsForUpload[0].ext,
                    client_id: this.form.client_id
                };
                API.testImportSpots(
                    data,
                    data => {
                        if (data.status === 200) {
                            this.$snotify.success(data.message);
                            this.isTesting = false;
                        } else {
                            this.isTesting = false;
                            this.$snotify.error(data.message);
                        }

                        this.failures = [];
                        this.error_responses = [];

                    },
                    err => {
                        this.$snotify.error("Please fix mentioned errors in your import");

                        if (typeof err.response.data.failures !== 'undefined') {
                            this.failures = err.response.data.failures;
                        } else {
                            this.error_responses = err.response.data.error_responses;
                        }
                        this.isTesting = false;
                    }
                );
            },
            importImagesFile: function () {
                this.isUploading = true;
                this.failures = [];
                const data = {
                    file: this.fileRecordsForUpload[0].file,
                    ext: this.fileRecordsForUpload[0].ext,
                    client_id: this.form.client_id
                };
                API.importImagesFile(
                    data,
                    data => {
                        if (data.status === 200) {
                            this.$snotify.success(data.message);
                            this.isUploading = false;
                            this.failures = [];
                            this.error_responses = [];
                            if(data.data) {
                                this.spotsData = data.data;
                                // this.spotsData = data.data;
                            }
                        } else {
                            this.$snotify.error(data.message);
                            this.isUploading = false;
                        }
                    },
                    err => {
                        this.$snotify.error("Please fix mentioned errors in your import");
                        if (typeof err.response.data.failures !== 'undefined') {
                            this.failures = err.response.data.failures;
                        } else {
                            this.error_responses = err.response.data.error_responses;
                        }
                        this.isUploading = false;
                    }
                );
            },
            uploadImportMedia: function () {
                this.mediaFilesData = [];
                this.isMediaUpload = true;
                this.mediaRecordsForUpload.forEach((media, index) => {
                    this.mediaFilesData.push(media.file);
                });
                const data = {
                    files: this.mediaFilesData,
                    client_id: this.client.client_id,
                    spotsData: JSON.stringify(this.spotsData),
                };
                // console.log(data.client_id);
                API.uploadImportMedia(
                    data,
                    data => {
                        if (data.status === 200) {
                            this.$snotify.success(data.message);
                            this.isMediaUpload = false;
                            this.uploadedFilesData = data.data;
                            this.mediaFilesData = [];
                            // this.mediaRecordsForUpload = [];
                            // this.mediaRecords = [];
                        } else {
                            this.$snotify.error(data.message);
                            this.isMediaUpload = false;
                        }
                    },
                    err => {
                        this.$snotify.error("Unknown error occurred");
                        this.isMediaUpload = false;
                    }
                );
            },
            submitImportMedia: function () {
                this.mediaFilesData = [];
                this.isMediaUpload = true;
                this.mediaRecordsForUpload.forEach((media, index) => {
                    this.mediaFilesData.push(media.file);
                });
                const data = {
                    files: this.mediaFilesData,
                    client_id: this.client.client_id,
                    spotsData: JSON.stringify(this.spotsData),
                    uploadedFilesData: JSON.stringify(this.uploadedFilesData),
                };
                // console.log(data.client_id);
                API.submitImportMedia(
                    data,
                    data => {
                        if (data.status === 200) {
                            this.$snotify.success(data.message);
                            this.isMediaUpload = false;
                            this.uploadedFilesData = data.data;
                            this.mediaFilesData = [];
                            this.mediaRecordsForUpload = [];
                            this.spotsData = [];
                        } else {
                            this.$snotify.error(data.message);
                            this.isMediaUpload = false;
                        }
                    },
                    err => {
                        this.$snotify.error("Unknown error occurred");
                        this.isMediaUpload = false;
                    }
                );
            },
            deleteUploadedFile: function (fileRecord) {
                this.$refs.vueFileAgent.deleteUpload(
                    this.uploadUrl,
                    this.uploadHeaders,
                    fileRecord
                );
            },
            filesSelected: function (fileRecordsNewlySelected) {
                var validFileRecords = fileRecordsNewlySelected.filter(
                    fileRecord => !fileRecord.error
                );
                this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
                    validFileRecords
                );
            },
            onBeforeDelete: function (fileRecord) {
                var i = this.fileRecordsForUpload.indexOf(fileRecord);
                if (i !== -1) {
                    // queued file, not yet uploaded. Just remove from the arrays
                    this.fileRecordsForUpload.splice(i, 1);
                    var k = this.fileRecords.indexOf(fileRecord);
                    if (k !== -1) this.fileRecords.splice(k, 1);
                } else {
                    if (confirm("Are you sure you want to delete?")) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
                    }
                }
            },
            fileDeleted: function (fileRecord) {
                var i = this.fileRecordsForUpload.indexOf(fileRecord);
                if (i !== -1) {
                    this.fileRecordsForUpload.splice(i, 1);
                } else {
                    this.deleteUploadedFile(fileRecord);
                }
            },
            deleteUploadedMedia: function (fileRecord) {
                this.$refs.mediaFiles.deleteUpload(
                    this.uploadUrl,
                    this.uploadHeaders,
                    fileRecord
                );
            },
            mediaSelected: function (fileRecordsNewlySelected) {
                var validFileRecords = fileRecordsNewlySelected.filter(
                    fileRecord => !fileRecord.error
                );
                this.mediaRecordsForUpload = this.mediaRecordsForUpload.concat(
                    validFileRecords
                );
                this.mediaStores = this.mediaRecordsForUpload
            },
            onBeforeMediaDelete: function (fileRecord) {
                var i = this.mediaRecordsForUpload.indexOf(fileRecord);
                if (i !== -1) {
                    // queued file, not yet uploaded. Just remove from the arrays
                    this.mediaRecordsForUpload.splice(i, 1);
                    var k = this.mediaRecords.indexOf(fileRecord);
                    if (k !== -1) this.mediaRecords.splice(k, 1);
                } else {
                    if (confirm("Are you sure you want to delete?")) {
                        this.$refs.mediaFiles.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                }
            },
            mediaDeleted: function (fileRecord) {
                var i = this.mediaRecordsForUpload.indexOf(fileRecord);
                if (i !== -1) {
                    this.mediaRecordsForUpload.splice(i, 1);
                } else {
                    this.deleteUploadedMedia(fileRecord);
                }
            },
            getAppSettings() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem("client_id"))
                };
                API.getAppSettings(
                    data,
                    data => {
                        // console.log(data.data.require_photo);
                        // this.imageError = false
                        this.imageError = data.data.require_photo === 1 ? "true" : "";
                    },
                    err => {
                    }
                );
            },
            async onComplete() {
                await this.submitImportMedia();
                this.fileRecords = [];
                // this.mediaRecords = [];
                this.mediaFilesData = [];
                this.fileRecordsForUpload = [];
                this.$refs.formwizard.changeStatus();
                this.$snotify
                    .success("Import Completed successfully!", {
                        timeout: 1000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true
                    })
                    .on("destroyed", () => this.$router.push({
                        name: "spots"
                    }));
            },
            reset() {
                this.fileRecords = [];
                this.mediaRecords = [];
                this.mediaFilesData = [];
                this.fileRecordsForUpload = [];
            },
            nextStep() {
                // if(this.istesting) {
                // this.$snotify.error('Please test your file first before upload.');
                // }
                // alert("On Next Step");
            },
            previousStep() {
                //alert("On Previous Step");
            }
        },
        computed: {}
    };
</script>

<style scoped>
    .failure-div {
        max-height: 300px;
        overflow-y: scroll;
    }

    table.import-tabe {
        background: #fff;
        padding: 15px;
        margin-bottom: 20px;
    }
    @media (max-width: 1150px) {
        .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
    
</style>
